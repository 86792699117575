<template>
  <div id="new-zgb-team-members-modifiy">
    <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
      <upload-selected-member-document
        :record="member"
        v-if="memberDocumentPrompt"
        :showDialog="memberDocumentPrompt"
        @hideDialog="hideMemberDocumentPrompt($event)"
      ></upload-selected-member-document>
    </v-dialog>
    <v-dialog v-model="memberPhotoPrompt" persistent scrollable>
      <upload-selected-member-photo
        :record="member"
        v-if="memberPhotoPrompt"
        :showDialog="memberPhotoPrompt"
        @hideDialog="hideMemberPhotoPrompt($event)"
      ></upload-selected-member-photo>
    </v-dialog>
    <v-card>
      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-card-title>
          <span class="headline">
            {{ pageDescription }} {{ NextYearName }}
          </span>
        </v-card-title>
        <v-card-text>
          <small>*indicates required field</small>
          <v-container>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  @click.prevent="refreshPageData"
                  color="#a4c639"
                >
                  Refresh
                </v-btn>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="6">
                <label>*Designation</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DesignationIdOptionsLoading"
                  :items="DesignationIdOptions"
                  :rules="DesignationIdRules"
                  v-model="DesignationId"
                  required
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <!-- <v-col cols="12" md="6">
                <label>*Member Zone</label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" md="6">
                <label>*Member LOM</label>
                <v-autocomplete
                  @change="getMemberIdOptions"
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <label>*Select Member</label>
                <v-autocomplete
                  @change="getMemberDetails"
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item, index }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="MemberId > 0">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img width="100" height="auto" :src="member.photo" />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Member Name</label>
                <h5>{{ member.member_name }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Membership Id</label>
                <h5>{{ member.membership_id }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Mobile No</label>
                <h5>{{ member.mobile_no }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Email Id</label>
                <h5>{{ member.email_id }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="8">
                <label>Address</label>
                <h5>{{ member.address_txt }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Date of Birth</h4>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="member.member_dob"
                      label="From Date"
                      hint="Select a From Date"
                      persistent-hint
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="member.member_dob"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Profile Update</h4>
                <v-chip
                  :color="`${
                    member.profile_updated == 1 ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{ member.profile_updated == 1 ? "Completed" : "Pending" }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Photo Update</h4>
                <v-chip
                  :color="`${member.photo_flag ? '#0f9d58' : '#f4b400'}`"
                  draggable
                  dark
                  >{{ member.photo_flag ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberPhotoPrompt = true"
                  v-if="member.photo_flag == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Age Proof Document Upload</h4>
                <v-chip
                  :color="`${member.document_flag ? '#0f9d58' : '#f4b400'}`"
                  draggable
                  dark
                  >{{ member.document_flag ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberDocumentPrompt = true"
                  v-if="member.document_flag == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="SubmitFlag"
            :disabled="!valid1"
            @click="confirmAlert"
            >Save</v-btn
          > -->
          <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-5 mr-2 white--text"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!valid1"
            @click.prevent="confirmAlert"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-5 mr-2 white--text"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import UploadSelectedMemberDocument from "@/view/pages/members/UploadSelectedMemberDocument.vue";
import UploadSelectedMemberPhoto from "@/view/pages/members/UploadSelectedMemberPhoto.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    UploadSelectedMemberDocument,
    UploadSelectedMemberPhoto,
  },
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      refreshButtonFlag: false,
      menu1: false,

      memberDocumentPrompt: false,
      memberPhotoPrompt: false,

      member: {},

      rows: [],
      item: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "Lom is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Region is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      DesignationIdRules: [(v) => !!v || "Designation is required"],
      DesignationId: "",
      DesignationIdOptions: [],
      DesignationIdOptionsLoading: false,

      AllMembers: [],

      CurrentYearId: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        this.CurrentYearId = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
    },
    LomCodeOptions: function () {
      this.LomCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      this.ZoneCodeOptionsLoading = false;
    },
    DesignationIdOptions: function () {
      this.DesignationIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        this.member.document_flag = true;
      }
    },
    hideMemberPhotoPrompt(flag) {
      console.log("hideMemberPhotoPrompt called");
      console.log({ flag });
      this.memberPhotoPrompt = false;
      if (flag) {
        this.member.photo_flag = true;
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "zgb_dis",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      var ZoneCode = this.$session.get("ZoneId");
      ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      this.ZoneCode = ZoneCode;

      this.getDesignationIdOptions();
      this.getLomCodeOptions();
    },
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      this.AllMembers = [];
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.member = member;
        this.AllMembers.push({
          YearwiseOfficesHeldId: null,
          MemberId: member.value,
          MemberName: member.member_name,
          DesignationId: this.DesignationId,
          Designation: "",
        });
      }
    },
    getDesignationIdOptions() {
      console.log("getDesignationIdOptions called");
      this.DesignationIdOptionsLoading = true;
      var selectbox1_source = "DesignationId";
      var selectbox1_destination = "DesignationIdOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        OrganizationTypeId: 2,
        ExceptDesignationId: [1, 4, 5, 6, 7],
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.LomCodeOptionsLoading = true;
      this.MemberId = "";
      var selectbox1_source = "LomCode";
      var selectbox1_destination = "LomCodeOptions";
      var selectbox1_url = "api/year-wise-lom/lom-options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        YearCode: this.JciYearCode,
        ZoneCode: this.ZoneCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      // this.MemberIdOptions = [];
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: this.LomCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      // add todo in store and clear all fields in dialog
      console.log("submitForm called");

      // this.resetMessageTxt();

      var result = this.$refs.form1.validate();

      var LomCode = this.LomCode;
      var ZoneCode = this.ZoneCode;
      var MemberId = this.MemberId;
      var DesignationId = this.DesignationId;
      var member = this.member;
      // var AllMembers = this.AllMembers;
      // var n1 = AllMembers.length;

      console.log(
        "LomCode=" +
          LomCode +
          ", MemberId=" +
          MemberId +
          ", DesignationId=" +
          DesignationId
      );

      if (result && LomCode != "" && MemberId != "" && DesignationId != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/year-wise-designation/new-governing-board";
        var upload = {
          UserInterface: 3,
          OrganizationTypeId: 2,
          YearCode: this.CurrentYearId,
          LomCode: LomCode,
          ZoneCode: ZoneCode,
          MemberId: MemberId,
          DesignationId: DesignationId,
          DateOfBirth: member.member_dob,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.ProgessStart = 0;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.SuccessMessageTxtFlag = 1;
              // thisIns.SuccessMessageTxt = output;

              thisIns.closePrompt();
              // thisIns.AllMembers = [];
              // thisIns.ResultFlag = false;
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "";
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (n1 == "") {
          message += "Atleast one member is required. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#new-zgb-team-members-modifiy {
  .v-dialog > .v-card > .v-toolbar {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .notes-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 16px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>